
import { QUIZ_VARIANTS } from '@/services/constants';

export default {
  components: {
    LoseWeightHeroDefault: () =>
      import('@/components/landing-pages/lose-weight/lose-weight-hero/variants/default/default'),
    LoseWeightHeroPilatesNow: () =>
      import('@/components/landing-pages/lose-weight/lose-weight-hero/variants/pilates-now/pilates-now'),
    LoseWeightHeroBodyNutrition: () =>
      import('@/components/landing-pages/lose-weight/lose-weight-hero/variants/body-nutrition/body-nutrition'),
    LoseWeightHeroBodyNutritionHighProtein: () =>
      import(
        '@/components/landing-pages/lose-weight/lose-weight-hero/variants/body-nutrition-high-protein/body-nutrition-high-protein'
      ),
    LoseWeightHeroNutritionKeto: () =>
      import('@/components/landing-pages/lose-weight/lose-weight-hero/variants/nutrition-keto/nutrition-keto'),
    LoseWeightHeroNutritionKetoMeal: () =>
      import(
        '@/components/landing-pages/lose-weight/lose-weight-hero/variants/nutrition-keto-meal/nutrition-keto-meal'
      ),
    LoseWeightHeroNutritionKetoAge: () =>
      import('@/components/landing-pages/lose-weight/lose-weight-hero/variants/nutrition-keto-age/nutrition-keto-age'),
    LoseWeightHeroPilatesLegalDisclaimer: () =>
      import(
        '@/components/landing-pages/lose-weight/lose-weight-hero/variants/pilates-legal-disclaimer/pilates-legal-disclaimer'
      ),
    LoseWeightHeroPilates28DayChallengeDisclaimer: () =>
      import(
        '@/components/landing-pages/lose-weight/lose-weight-hero/variants/pilates-28-day-challenge-disclaimer/pilates-28-day-challenge-disclaimer'
      ),
    LoseWeightHeroPilates28DayChairDisclaimer: () =>
      import(
        '@/components/landing-pages/lose-weight/lose-weight-hero/variants/pilates-28-day-chair-disclaimer/pilates-28-day-chair-disclaimer'
      ),
    LoseWeightHeroPilates28DayFlatBellyDisclaimer: () =>
      import(
        '@/components/landing-pages/lose-weight/lose-weight-hero/variants/pilates-28-day-flat-belly-disclaimer/pilates-28-day-flat-belly-disclaimer'
      ),
    LoseWeightHeroPilates28DayChallengeDisclaimerSomatic: () =>
      import(
        '@/components/landing-pages/lose-weight/lose-weight-hero/variants/pilates-28-day-challenge-disclaimer-somatic/pilates-28-day-challenge-disclaimer-somatic'
      ),
    Nutrition28DaySugarChallengeLoseWeight: () =>
      import(
        '@/components/landing-pages/lose-weight/lose-weight-hero/variants/nutrition-28-day-sugar-challenge/nutrition-28-day-sugar-challenge'
      ),
    Nutrition28DayWeightLossChallenge: () =>
      import(
        '@/components/landing-pages/lose-weight/lose-weight-hero/variants/nutrition-28-day-wl-challenge/nutrition-28-day-wl-challenge'
      ),
    LoseWeightHeroNutritionKetoWeekly: () =>
      import(
        '@/components/landing-pages/lose-weight/lose-weight-hero/variants/nutrition-keto-weekly/nutrition-keto-weekly'
      ),
    Yoga28DayChallengeHero: () =>
      import(
        '@/components/landing-pages/lose-weight/lose-weight-hero/variants/yoga-28-day-challenge/yoga-28-day-challenge'
      ),
    NutritionKetoWeeklyAge: () =>
      import(
        '@/components/landing-pages/lose-weight/lose-weight-hero/variants/nutrition-keto-weekly-age/nutrition-keto-weekly-age'
      ),
    NutritionKetoMealWeekly: () =>
      import(
        '@/components/landing-pages/lose-weight/lose-weight-hero/variants/nutrition-keto-meal-weekly/nutrition-keto-meal-weekly'
      ),
    YogaMenopauseChallenge: () =>
      import(
        '@/components/landing-pages/lose-weight/lose-weight-hero/variants/yoga-menopause-challenge/yoga-menopause-challenge'
      ),
    SomaticYoga: () =>
      import('@/components/landing-pages/lose-weight/lose-weight-hero/variants/somatic-yoga/somatic-yoga'),
    SomaticExercises: () =>
      import('@/components/landing-pages/lose-weight/lose-weight-hero/variants/somatic-exercises/somatic-exercise'),
    NutritionMonthlyGeneric: () =>
      import(
        '@/components/landing-pages/lose-weight/lose-weight-hero/variants/nutrition-monthly-generic/nutrition-monthly-generic'
      ),
  },
  props: {
    titleHero: {
      type: String,
      default: null,
    },
    landingPage: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    selectedVariant() {
      const variantsMap = {
        [QUIZ_VARIANTS.DEFAULT]: 'LoseWeightHeroDefault',
        [QUIZ_VARIANTS.PILATES_NEW_CHECKOUT]: 'LoseWeightHeroPilatesLegalDisclaimer',
        [QUIZ_VARIANTS.WALL_PILATES]: 'LoseWeightHeroPilatesLegalDisclaimer',
        [QUIZ_VARIANTS.PILATES_LEGAL_DISCLAIMER]: 'LoseWeightHeroPilatesLegalDisclaimer',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT]: 'LoseWeightHeroPilates28DayChallengeDisclaimer',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_META_CHANGES]: 'LoseWeightHeroPilates28DayChallengeDisclaimer',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_US]: 'LoseWeightHeroPilates28DayChallengeDisclaimer',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_GENERIC_OTHERS]: 'LoseWeightHeroPilates28DayChallengeDisclaimer',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHAIR_CHALLENGE]: 'LoseWeightHeroPilates28DayChairDisclaimer',
        [QUIZ_VARIANTS.PILATES_28_DAY_FLAT_BELLY]: 'LoseWeightHeroPilates28DayFlatBellyDisclaimer',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES]:
          'LoseWeightHeroPilates28DayChallengeDisclaimerSomatic',
        [QUIZ_VARIANTS.PILATES_NOW]: 'LoseWeightHeroPilatesNow',
        [QUIZ_VARIANTS.BODY_NUTRITION]: 'LoseWeightHeroBodyNutrition',
        [QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY]: 'LoseWeightHeroBodyNutrition',
        [QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY]: 'LoseWeightHeroBodyNutrition',
        [QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY_META_CHANGES]: 'LoseWeightHeroBodyNutrition',
        [QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY_HIGH_PROTEIN]: 'LoseWeightHeroBodyNutritionHighProtein',
        [QUIZ_VARIANTS.NUTRITION_WEEKLY_START]: 'LoseWeightHeroBodyNutrition',
        [QUIZ_VARIANTS.NUTRITION_KETO]: 'LoseWeightHeroNutritionKeto',
        [QUIZ_VARIANTS.NUTRITION_KETO_MEAL]: 'LoseWeightHeroNutritionKetoMeal',
        [QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY]: 'LoseWeightHeroNutritionKetoWeekly',
        [QUIZ_VARIANTS.NUTRITION_KETO_AGE]: 'LoseWeightHeroNutritionKetoAge',
        [QUIZ_VARIANTS.HEALTH_NO_SUGAR_CHALLENGE]: 'Nutrition28DaySugarChallengeLoseWeight',
        [QUIZ_VARIANTS.FAST_WEIGHT_LOSS]: 'LoseWeightHeroBodyNutrition',
        [QUIZ_VARIANTS.MACRO_DIET]: 'LoseWeightHeroBodyNutrition',
        [QUIZ_VARIANTS.OPTIMAL_WEIGHT]: 'LoseWeightHeroBodyNutrition',
        [QUIZ_VARIANTS.WEIGHT_LOSS_OVER_40]: 'LoseWeightHeroBodyNutrition',
        [QUIZ_VARIANTS.WEIGHT_LOSS_WITHOUT_FASTING]: 'LoseWeightHeroBodyNutrition',
        [QUIZ_VARIANTS.HEALTH_FOOD_IMPACT]: 'LoseWeightHeroBodyNutrition',
        [QUIZ_VARIANTS.METABOLIC_RENEWAL]: 'LoseWeightHeroBodyNutrition',
        [QUIZ_VARIANTS.CHOLESTEROL_CONTROL]: 'LoseWeightHeroBodyNutrition',
        [QUIZ_VARIANTS.WEIGHT_LOSS_SECRET]: 'LoseWeightHeroBodyNutrition',
        [QUIZ_VARIANTS.HEALTH_WEIGHT_LOSS_28_DAY_CHALLENGE]: 'Nutrition28DayWeightLossChallenge',
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE]: 'Yoga28DayChallengeHero',
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_META_CHANGES]: 'Yoga28DayChallengeHero',
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_FULL_BODY_BEFORE_AFTER_IMAGES_V1]: 'Yoga28DayChallengeHero',
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_FULL_BODY_BEFORE_AFTER_IMAGES_V2]: 'Yoga28DayChallengeHero',
        [QUIZ_VARIANTS.YOGA_MENOPAUSE_CHALLENGE]: 'YogaMenopauseChallenge',
        [QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY_AGE]: 'NutritionKetoWeeklyAge',
        [QUIZ_VARIANTS.NUTRITION_KETO_MEAL_WEEKLY]: 'NutritionKetoMealWeekly',
        [QUIZ_VARIANTS.SOMATIC_YOGA]: 'SomaticYoga',
        [QUIZ_VARIANTS.SOMATIC_EXERCISES]: 'SomaticExercises',
        [QUIZ_VARIANTS.NUTRITION_MONTHLY_GENERIC_US]: 'NutritionMonthlyGeneric',
        [QUIZ_VARIANTS.NUTRITION_MONTHLY_GENERIC_OTHERS]: 'NutritionMonthlyGeneric',
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_GENERIC_US]: 'Yoga28DayChallengeHero',
        [QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_GENERIC_OTHERS]: 'Yoga28DayChallengeHero',
        [QUIZ_VARIANTS.PILATES_28_DAY]: 'LoseWeightHeroPilatesLegalDisclaimer',
        [QUIZ_VARIANTS.PILATES_28_D]: 'LoseWeightHeroPilatesLegalDisclaimer',
      };

      return variantsMap[this.getQuizVariant] || variantsMap[QUIZ_VARIANTS?.DEFAULT];
    },
  },
};
